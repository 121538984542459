<template>
    <Guest>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white uppercase">
                <i18n-t keypath="register.title" />
            </h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-3xl">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form>

                    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <TextInput v-model:value="form.first_name.value" v-model:errors="form.first_name.errors" :label="$t('account.personal.first_name.title')" :placeholder="$t('account.personal.first_name.placeholder')" border="enable" />
                        <TextInput v-model:value="form.last_name.value" v-model:errors="form.last_name.errors" :label="$t('account.personal.last_name.title')" :placeholder="$t('account.personal.last_name.placeholder')" border="enable" />
                        <TextInput name="email" v-model:value="form.email.value" v-model:errors="form.email.errors" :label="$t('account.email.email.title')" :placeholder="$t('account.email.email.placeholder')" border="enable" />
                        <TextInput v-model:value="form.email_confirmation.value" v-model:errors="form.email_confirmation.errors" :label="$t('account.email.confirmation.title')" :placeholder="$t('account.email.confirmation.placeholder')" border="enable" />
                        <DateInput v-model:value="form.birth_day.value" v-model:errors="form.birth_day.errors" :label="$t('account.personal.birth_day')" border="enable" />
                        <SelectInput v-model:value="form.locale.value" v-model:errors="form.locale.errors" :options="locale_options" label="Taal / Language" border="enable" />
                    </div>

                    <hr class="mb-5 mt-6 bg-gray-900">

                    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <TextInput name="password" v-model:value="form.password.value" v-model:errors="form.password.errors" :label="$t('account.password.password.title')" :placeholder="$t('account.password.password.placeholder')" password border="enable" />
                        <TextInput v-model:value="form.password_confirmation.value" v-model:errors="form.password_confirmation.errors" :label="$t('account.password.confirmation.title')" :placeholder="$t('account.password.confirmation.placeholder')" password border="enable" />
                    </div>

                    <hr class="mb-5 mt-6 bg-gray-900">

                    <div class="grid gap-4">
                        <SelectInput v-model:value="form.sport_id.value" v-model:errors="form.sport_id.errors" :options="sports_options" :label="$t('profile.sport.title')" border="enable" />

                        <RadioSelect
                            v-model:value="form.isTrainer.value"
                            v-model:errors="form.isTrainer.errors"
                            :options="coachOrExpertOptions" 
                            :label="$t('register.coachOrExpert.are_you_coach_or_expert')"
                            name="is_trainer"
                        />
                    </div>

                    <hr class="mb-5 mt-6 bg-gray-900">

                    <div class="pt-3">
                        <CheckInput v-model:value="form.terms_and_conditions.value" v-model:errors="form.terms_and_conditions.errors" id="tac" class="pb-2">
                            <i18n-t keypath="register.acceptTerms">
                                <template v-slot:terms>
                                    <a target="_blank" class="underline" href="https://www.scorelit.com/voorwaarden/">
                                        <i18n-t keypath="register.terms" />
                                    </a>
                                </template>
                                <template v-slot:privacy>
                                    <a target="_blank" class="underline" href="https://www.scorelit.com/privacy-statement/">
                                        <i18n-t keypath="register.privacy" />
                                    </a>
                                </template>
                            </i18n-t>
                        </CheckInput>
                        <CheckInput v-model:value="form.accept_newsletter.value" v-model:errors="form.accept_newsletter.errors" id="news" class="pb-2">
                            <i18n-t keypath="register.newsletter" />
                        </CheckInput>
                    </div>
                </form>
            </div>
            <div class="mt-6">
                <router-link :to="{ name: 'login'}">
                    <span class="inline-block w-5/12 sm:w-3/12 rounded-md shadow-sm">
                        <button type="button" class="button-transparent w-full">
                            <i18n-t keypath="general.back" />
                        </button>
                    </span>
                </router-link>
                <span class="inline-block w-5/12 rounded-md shadow-sm float-right">
                    <button type="submit" @click="register" class="button w-full">
                        <i18n-t keypath="register.submit" />
                    </button>
                </span>
            </div>
        </div>
    </Guest>
</template>

<script>
import { register } from "@/helper/api/RegistrationHelper";
import { indexSport } from "@/helper/api/SportHelper";
import router from "@/router";
import Guest from "@/wrappers/Guest";
import TextInput from "@/components/forms/TextInput";
import CheckInput from "@/components/forms/CheckInput";
import DateInput from "@/components/forms/DateInput";
import SelectInput from "@/components/forms/SelectInput";
import RadioSelect from "@/components/forms/RadioSelect";
import { setLoading } from "@/helper/store/loadingSpinnerHelper";
import i18n, { setI18nLanguage } from "../i18n";
const $t = i18n.global.t;

export default {
    name: "Register",
    components:{
        Guest,
        TextInput,
        CheckInput,
        DateInput,
        SelectInput,
        RadioSelect,
    },
    created(){
        this.loadSports()
    },
    data() {
        return {
            sports_options: undefined,
            form: {
                first_name: {
                    value: '',
                    errors: []
                },
                last_name: {
                    value: '',
                    errors: []
                },
                birth_day: {
                    value: '',
                    errors: []
                },
                email: {
                    value: '',
                    errors: []
                },
                email_confirmation: {
                    value: '',
                    errors: []
                },
                password: {
                    value: '',
                    errors: []
                },
                password_confirmation: {
                    value: '',
                    errors: []
                },
                sport_id: {
                    value: '',
                    errors: []
                },
                terms_and_conditions: {
                    value: false,
                    errors: []
                },
                accept_newsletter: {
                    value: false,
                    errors: []
                },
                locale: {
                    value: i18n.global.locale.startsWith('nl') ? 'nl-NL' : 'en-GB',
                    errors: []
                },
                isTrainer: {
                    value: "",
                    errors: []
                }
            },
            error_message: "",
        }
    },
    watch: {
        "form.locale.value"(newval) {
            setI18nLanguage(newval);
        }
    },
    computed: {
        locale_options() {
            return [
                { key: 'nl-NL', text: 'Nederlands' },
                { key: 'en-GB', text: 'English'}
            ];
        },
        coachOrExpertOptions() {
            return [
                { key: "", text: $t('register.coachOrExpert.expert'), description: $t('register.coachOrExpert.expert_desc') },
                { key: "1", text: $t('register.coachOrExpert.trainer'), description: $t('register.coachOrExpert.trainer_desc') },
            ]
        }
    },
    methods: {
        register() {
            setLoading(true);

            register(this.form)
                .then(()=>{
                    router.push("/dashboard");
                })
                .catch((data)=>{
                    this.error_message = data.message;
                    for (let error in data.errors) {
                        this.form[error].errors = data.errors[error];
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        loadSports(){
            indexSport()
                .then(sports => {
                    this.sports_options = sports.map(sport => {return {key: sport.id, text: sport.name}});
                })
                .catch(err => console.log(err));
        },
    }
}
</script>

<style scoped>

</style>
