<template>
    <div>
        <div class="flex items-center">
            <input ref="input" :checked="value" :value="value" @input="updateValue()" @change="clearErrors()" :id="id" type="checkbox" class="transition duration-150 ease-in-out">
            <label :for="id" class="ml-3 sm:ml-2 block text-xs sm:text-sm leading-5 text-gray-900 font-medium">
                <slot />
            </label>
        </div>
        <template v-if="hasErrors">
            <p v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600 font-medium">{{ error }}</p>
        </template>
    </div>
</template>

<script>
export default {
    name: "TextInput",
    props: ['value', 'errors', 'id'],
    computed:{
        hasErrors: function(){
            return this.errors && this.errors.length !== 0;
        },
        inputClasses: function () {
            if (this.hasErrors){
                return "form-input block w-full pr-10 border border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red sm:text-sm sm:leading-5";
            } else {
                return "form-input block w-full border sm:text-sm sm:leading-5";
            }
        },
    },
   
    methods:{
        updateValue(){
            this.$emit('update:value', this.$refs.input.checked == true);
        },
        clearErrors(){
            this.$emit('update:errors', []);
        }
    }
}
</script>

<style scoped>

</style>