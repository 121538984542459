<template>
  <div class="bg-white rounded-md">
    <label
      v-if="label"
      :for="name"
      class="block text-xs sm:text-sm leading-5 font-medium"
      :class="theme === 'dark' ? 'text-white' : 'text-gray-900'"
    >
      {{ label }}
    </label>
    <div class="mt-2 divide-y border border-gray-300 w-full rounded">
      <div
        v-for="(option, i) in options"
        :key="option.key"
        class="relative p-4 flex cursor-pointer dark:bg: dark:border-gray-800"
        :class="{
          'bg-gray-100': isOptionSelected(option),
          'cursor-not-allowed dark:bg-gray-700': option.disabled,
          'cursor-pointer dark:bg-gray-600': !option.disabled,
        }"
        @click="selectOption(option)"
      >
        <div class="flex items-center h-5">
          <input
            :id="`${name}-option-${i}`"
            :checked="isOptionSelected(option)"
            :name="name"
            :value="option.key"
            :disabled="option.disabled"
            @change="selectOption(option)"
            type="radio"
            class="h-4 w-4"
            :style="{ 'accent-color': '#EF4136' }"
            :class="{
              'cursor-not-allowed': option.disabled,
            }"
          />
        </div>
        <label
          :for="`${name}-option-${i}`"
          class="ml-3 flex flex-col"
          :class="{
            'cursor-not-allowed': option.disabled,
            'cursor-pointer': !option.disabled,
          }"
        >
          <span
            :class="{
              'text-black': isOptionSelected(option),
              'text-gray-800':
                !isOptionSelected(option) && !option.disabled,
              'text-gray-500': option.disabled,
            }"
            class="block text-sm font-medium font-bold"
          >
            {{ option.text }}
          </span>
          <span
            :class="
              isOptionSelected(option)
                ? 'text-gray-800'
                : 'text-gray-500'
            "
            class="block text-sm"
          >
            {{ option.description }}
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioSelect",
  props: ["name", "value", "errors", "label", "options"],
  emits: {
    "update:value": (val) => typeof val === "string",
    "update:errors": (val) => Array.isArray(val),
  },
  computed: {
    hasErrors: function() {
      return this.errors && this.errors.length !== 0;
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("update:value", option.key);
      this.clearErrors();
    },
    isOptionSelected(option) {
      return option.key === this.value;
    },
    clearErrors() {
      this.$emit("update:errors", []);
    },
  },
};
</script>
